import type { PaginationByOffset } from '@nuxtjs/strapi'
import type { Ref } from 'vue'
import type { IssueWithLoadingState, IssuesTypes } from '~/stores/issues/types'

export enum IssueSortTypes {
  DEFAULT = 'default',
  COLLATERALS = 'dmatChecks',
  MFCR_SCORE = 'scoreData.score',
  ANNUAL_INTEREST_RATE = 'interestRate.annualInterestRate',
  VIEWS = 'views',
  AVAILABLE_COUNT = 'availableCount',
}

export { IssuesTypes, type ExtraIssuesListFilters, type IssuesListFilters } from '~/stores/issues/types'
export type IssuesListPagination = PaginationByOffset

export type IssueTypeState = {
  total: number
  pagination: IssuesListPagination
  isLoading: boolean
  list: IssueWithLoadingState[]
}

export type IssuesTypesState = Record<IssuesTypes, Ref<IssueTypeState>>
