import dayjs from 'dayjs'
import { BondIssueType } from '../getIssuesList.types'
import type { IssuesFiltersState } from '~/stores/issues/useIssuesStore'
import { UNKNOWN_BUSINESS_CATEGORY_ID } from '~/constants/issue'

/** Formats issueMaturity as a date where issueMaturity is a number of years added to today's date */
export function getIssueMaturityRange(issueMaturity: IssuesFiltersState['issueMaturity']) {
  if (!issueMaturity) {
    return null
  }

  return {
    from: dayjs().add((issueMaturity[0] - 0.5) * 12, 'month').add(1, 'day').unix(),
    to: dayjs().add((issueMaturity[1] + 0.5) * 12, 'month').subtract(1, 'day').unix(),
  }
}

export const mfCrScore = [
  { min: 0, max: 3.5, value: 1 },
  { min: 4, max: 7, value: 2 },
  { min: 7.5, max: 11, value: 3 },
  { min: 11.5, max: 15, value: 4 },
  { min: 15.5, max: 19, value: 5 },
]

export function getScoreByMfCrScoreRangeValue(rangeValue: number): number {
  return mfCrScore.find(({ min, max }) => rangeValue >= min && rangeValue <= max)
    ?.value ?? 0
}
const today = dayjs().endOf('date').unix()
export function prepareFilter(filters: IssuesFiltersState) {
  const filter: { $and: Record<string, unknown>[] } = {
    $and: [],
  }

  if (filters.notEqualSlug) {
    filter.$and.push({ slug: { $ne: filters.notEqualSlug } })
  }

  const mfcrScore = filters.mfcrScore

  if (mfcrScore) {
    filter.$and.push({
      scoreData: {
        score: {
          $gte: mfcrScore[0],
          $lte: mfcrScore[1],
        },
      },
    })
  }

  if (filters.annualInterestRate) {
    filter.$and.push({
      interestRate: {
        annualInterestRate: {
          $gte: filters.annualInterestRate[0],
          $lte: filters.annualInterestRate[1],
        },
      },
    })
  }

  if (filters.dmatChecks) {
    filter.$and.push({
      dmatChecks: { $gte: filters.dmatChecks },
    })
  }

  // TODO: CHECK THIS FILTER TO BE CORRECT
  if (filters.issueMaturity) {
    filter.$and.push({
      maturityDateUnix: {
        $gte: getIssueMaturityRange(filters.issueMaturity)?.from,
        $lte: getIssueMaturityRange(filters.issueMaturity)?.to,
      },
    })
  }

  if (filters.hasEarlyRepayment) {
    filter.$and.push({
      hasEarlyRepayment: { $eq: true },
    })
  }

  if (filters.isProspectusApprovedByCNB) {
    filter.$and.push({
      scoreData: {
        prospectusApprovedByIssuerAuthority: { $eq: true },
      },
    })
  }

  if (filters.canBeBoughtOnline) {
    filter.$and.push({
      $or: [
        { jamesOrderFormURL: { $notIn: [null, ''] } },
      ],
    })
  }

  if (filters.stateBonds) {
    filter.$and.push({
      type: { $eq: BondIssueType.State },
    })
  }

  if (!filters.stateBonds) {
    filter.$and.push({
      type: { $ne: BondIssueType.State },
    })
  }

  if (filters.excludedId) {
    filter.$and.push(
      ...filters.excludedId.map(id => ({ id: { $ne: id } })),
    )
  }

  if (filters.collateralTypes?.length) {
    filter.$and.push({
      $or: filters.collateralTypes.map(collateralType => ({
        collaterals: {
          type: {
            $eqi: collateralType,
          },
        },
      })),
    })
  }

  if (filters.isArchived) {
    filter.$and.push({
      $or: [
        { maturityDateUnix: { $lte: today } },
        { payoutDateUnix: { $lte: today } },
        { isAvailable: { $eq: false } },
      ],
    })
  }

  if (filters.isNotArchived) {
    filter.$and.push({
      $or: [
        { maturityDateUnix: { $gt: today } },
        { maturityDateUnix: { $null: false } },
      ],
    })

    filter.$and.push({
      $or: [
        { payoutDateUnix: { $gt: today } },
        { payoutDateUnix: { $null: true } },
      ],
    })

    filter.$and.push({
      $or: [
        { isAvailable: { $eq: true } },
        { isAvailable: { $null: true } },
      ],
    })
  }

  if (filters.onlyOur) {
    filter.$and.push({ top: { $gte: 1 } })
  }

  if (filters.excludedCompanyIds) {
    filter.$and.push({
      $and: filters.excludedCompanyIds.map(id => ({
        company: { id: { $ne: id } },
      })),
    })
  }

  return filter
}

export function prepareMeilisearchFilter(filters: IssuesFiltersState): string {
  const filter: string[] = [
    'publishedAt IS NOT NULL',

    'nominalValue IS NOT NULL',
    'nominalValue > 0',

    'slug IS NOT NULL',
    'slug IS NOT EMPTY',

    'name IS NOT NULL',
    'name IS NOT EMPTY',

    'currency IS NOT NULL',
    'currency IS NOT EMPTY',

    'interestRate EXISTS',
    'interestRate IS NOT NULL',
    'interestRate IS NOT EMPTY',
    'maturityDate IS NOT NULL',

    [
      '(interestRate.annualInterestRate > 0',
      'interestRate.realAnnualInterestRate > 0',
      'interestRate.variableYieldInterestRateType IS NOT NULL)',
    ].join(' OR '),

    'scoreData.score IS NOT NULL',
    'scoreData IS NOT NULL',
  ]

  if (filters.isAvailable) {
    filter.push('isAvailable = true')
  }

  if (filters.notEqualSlug) {
    filter.push(`slug != '${filters.notEqualSlug}'`)
  }

  const mfcrScore = filters.mfcrScore

  if (mfcrScore) {
    filter.push(`scoreData.score >= ${mfcrScore[0]}`)
    filter.push(`scoreData.score <= ${mfcrScore[1]}`)
  }

  if (filters.annualInterestRate) {
    filter.push(`interestRate.annualInterestRate >= ${filters.annualInterestRate[0]}`)
    filter.push(`interestRate.annualInterestRate <= ${filters.annualInterestRate[1]}`)
  }

  if (filters.dmatChecks) {
    filter.push(`dmatChecks >= ${filters.dmatChecks}`)
  }

  if (filters.issueMaturity) {
    filter.push(`maturityDateUnix >= ${getIssueMaturityRange(filters.issueMaturity)?.from}`)
    filter.push(`maturityDateUnix <= ${getIssueMaturityRange(filters.issueMaturity)?.to}`)
  }

  if (filters.hasEarlyRepayment) {
    filter.push('hasEarlyRepayment = true')
  }

  if (filters.isProspectusApprovedByCNB) {
    filter.push('scoreData.prospectusApprovedByIssuerAuthority = true')
  }

  if (filters.canBeBoughtOnline) {
    filter.push('jamesOrderFormURL IS NOT NULL')
  }

  if (filters.stateBonds) {
    filter.push(`type = '${BondIssueType.State}'`)
  }

  if (!filters.stateBonds) {
    filter.push(`type != '${BondIssueType.State}'`)
  }

  if (filters.excludedId) {
    filter.push(
      ...filters.excludedId.map(id => `id != ${id}`),
    )
  }

  if (filters.collateralTypes?.length) {
    const collaterals = filters.collateralTypes
      .map(collateralType => `'${collateralType}'`)
      .join(', ')
    filter.push(`collaterals.type IN [${collaterals}]`)
  }

  if (filters.isArchived) {
    filter.push(`(maturityDateUnix <= ${today} OR payoutDateUnix <= ${today} OR isAvailable = false)`)
  }

  if (filters.isNotArchived) {
    filter.push(`(maturityDateUnix > ${today} OR maturityDate IS NULL)`)
    filter.push(`(payoutDateUnix > ${today} OR payoutDate IS NULL)`)
    filter.push('(isAvailable = true OR isAvailable IS NULL)')
  }

  if (filters.onlyOur) {
    filter.push('top >= 1')
  }

  if (filters.excludedCompanyIds) {
    filter.push(
      ...filters.excludedCompanyIds.map(id => `company.id != ${id}`),
    )
  }

  if (filters.businessCategory?.length) {
    const selectedCategories = filters.businessCategory?.filter(id => id !== UNKNOWN_BUSINESS_CATEGORY_ID) || []
    const unknownCategoryIncludedFilter = filters.businessCategory?.includes(UNKNOWN_BUSINESS_CATEGORY_ID) ? 'company.businessCategory IS NULL' : ''

    if (selectedCategories.length) {
      const businessCategories = `company.businessCategory.id IN [${selectedCategories.filter(id => id !== UNKNOWN_BUSINESS_CATEGORY_ID).join(', ')}]`
      filter.push(unknownCategoryIncludedFilter ? `(${businessCategories} OR ${unknownCategoryIncludedFilter})` : businessCategories)
    }
    else {
      filter.push(unknownCategoryIncludedFilter)
    }
  }

  return filter.join(' AND ')
}
