import type { Filter } from 'meilisearch'
import type { ShortIssueWithId, getIssuesListServiceInput } from '../getIssuesList.types'
import { ShortIssueKeys, ShortIssuesPopulateKeys, ShortIssuesPopulateKeysDeep } from '../getIssuesList.types'
import { prepareMeilisearchFilter } from '../helpers/functions'
import { IssueSortTypes } from '~/composables/issues/useIssuesList/types'
import { useSentry } from '~/composables/sentry/useSentry'
import type { ApiResponseData, ApiResponseMeta } from '~~/api/types'

type MeiliSearchRequestParams = {
  filter?: Filter
  [key: string]: any
}

export async function getIssuesList({
  filters,
  pagination,
  sort,
  search = '',
  includeDefaultSort = true,
}: getIssuesListServiceInput): Promise<ApiResponseData<ShortIssueWithId[]>> {
  const resultedSort: string[] = [
    ...(sort ? [`${sort.key}:${sort.direction}`] : []),
    ...(includeDefaultSort ? ['top:desc'] : []),
    ...(sort?.key !== IssueSortTypes.COLLATERALS ? ['dmatChecks:desc'] : []),
    ...(includeDefaultSort ? ['createdAt:desc', 'id:asc'] : []),
  ]

  const client = useMeilisearch()

  const index = client.index('issues')

  const params: MeiliSearchRequestParams = {
    fields: Object.values(ShortIssueKeys),
    sort: resultedSort,
    populate: [
      ...Object.values(ShortIssuesPopulateKeys),
      ...Object.values(ShortIssuesPopulateKeysDeep),
    ],
  }

  if (filters) {
    params.filters = prepareMeilisearchFilter(filters)
  }

  if (pagination) {
    params.pagination = pagination
  }
  try {
    const response = await index.search<ShortIssueWithId>(search || '', {
      filter: params.filters,
      sort: resultedSort,
      offset: pagination?.offset || 0,
      limit: pagination?.limit || 0,
    })

    return response
      ? {
          data: response.hits,
          meta: {
            pagination: {
              page: 1,
              pageSize: response.limit,
              pageCount: response.estimatedTotalHits % response.limit,
              total: response.estimatedTotalHits,
            },
          } as ApiResponseMeta,
        }
      : { data: [] }
  }
  catch (_) {
    await useSentry().captureException(
      new Error('Unable to fetch issues from Meilisearch'),
      {
        filters,
        sort,
        search,
      },
    )
    return { data: [] }
  }
}
